import Bannerimage from './asset/images/banner-section-image.png';
import Dextrade from './asset/images/new-img/dex-trade.png'
import Coinsbit from './asset/images/new-img/Coinsbit.png'
import Cuptoken from './asset/images/new-img/cuptoken.png'
import Coinstore from './asset/images/new-img/Coinstore.png'
import Dusd from './asset/images/new-img/dusd-logo.png'
import Dict from './asset/images/new-img/dict.png'
import Coinmarketcap from './asset/images/new-img/Coinmarketcap.png'
import Coinge from './asset/images/new-img/coinge.png'

import Footer from './Footer'
import './App.css';

function App() {
  return (
    <div className="App">


      <section class="hero-banner-section">
        <div class="container">
          <div class="row  align-items-start min-vh-100 align-items-center flex-lg-row-reverse">
            <div class="col-lg-6">
              <img src={Bannerimage} alt='Bannerimage' />
            </div>
            <div class="col-lg-6">
              <h1 class="banner-text-1 mb-4"> Welcome to the <br />D-Ecosystem Sales</h1>
              <p class="banner-text-2">Find our Project Token Sales</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 hero-banner-section-2">
        <div className="container">
          <h2 className="banner-text-3 mb-3 text-center"> The Native DCX Coin of D-Chain Network</h2>
          <h5 className='mb-5 banner-text-5 text-center'>The DCX Coin is a digital currency known for its efficiency and security, designed for seamless and reliable transactions within the D-Eco ecosystem.</h5>
          <div class="row row-cols-1 row-cols-lg-3 g-4 col-lg-10 mx-auto justify-content-center">
            <div class="col">
              <a href="https://www.coinstore.com/spot/DCXUSDT#/spot/DCXUSDT" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Coinstore} alt="Coinstore" style={{ filter: "brightness(0) invert(1)" }} />
                    <h6 className='fw-bold banner-text-4 mt-2'>Buy on Coinstore</h6>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://dex-trade.com/spot/trading/DCXUSDT?interface=classic" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Dextrade} alt="Dextrade" style={{ filter: "brightness(0) invert(1)" }} />
                    <h6 className='fw-bold banner-text-4 mt-2'>Buy on Dex Trade</h6>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a href="https://www.d-ecosystem.io/comingsoon" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Coinsbit} alt="Coinsbit" />
                    <h6 className='fw-bold banner-text-4 mt-2'>Buy on Coinsbit</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 hero-banner-section-2">
        <div className="container">
          <div class="row g-4 justify-content-between">
            <div class="col-lg-8">
              <h2 className="banner-text-3 mb-4"> The DUSD Backed by Gold <img className='heading-token-image' src={Dusd} alt='Dusd' /></h2>
              <h5 className='mb-5 banner-text-5 w-75'>DUSD is a stablecoin uniquely backed by real gold, offering a secure and stable digital currency option within the D-Eco ecosystem.</h5>
            </div>
            <div class="col-lg-3">
              <a href="https://www.d-ecosystem.io/comingsoon" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Dextrade} alt="Dextrade" style={{ filter: "brightness(0) invert(1)" }} />
                    <h6 className='fw-bold banner-text-4 mt-3'>Buy on Dex Trade</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 hero-banner-section-2">
        <div className="container">
          <div class="row g-4 justify-content-between">
            <div class="col-lg-8">
              <h2 className="banner-text-3 mb-4"> The Copper Powder Token CUP <img className='heading-token-image' src={Cuptoken} alt='Cuptoken' /></h2>
              <h5 className='mb-5 banner-text-5 w-75'>The CUP Token represents an innovative digital asset in the D-Eco ecosystem, directly linked to the value of ultra fine copper powder, combining traditional commodity value with modern blockchain technology.</h5>
            </div>
            <div class="col-lg-3">
              <a href="https://presale.cuptoken.trade/" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Dict} alt="DICTYS" />
                    <h6 className='fw-bold banner-text-4 mt-3'>Buy on DICTYS</h6>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 hero-banner-section-3">
        <div className="container">
          <h4 className='text-center mb-5 fw-bold' style={{ letterSpacing: "1px" }}>Follow our Latest Updated</h4>
          <div class="row g-4 justify-content-around">
            <div class="col-lg-5">
              <a href="https://coinmarketcap.com/currencies/d-ecosystem/" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Coinmarketcap} alt="Coinmarketcap" />
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-5">
              <a href="https://www.coingecko.com/en/coins/d-ecosystem" target="_blank" rel="noreferrer">
                <div class="card">
                  <div className='card-body'>
                    <img src={Coinge} alt="coingecko" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default App;
